<template>
    <h3>Order details </h3>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    name: "order details",
    data() {
        return {

        }

    },

     mounted() {
       this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Order details" },
        
      ]);
    },
    
}
</script>